var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"e8f716aac74bcb032b39190e52a464b4046be531"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";

import { clientEnv } from "@/lib/clientSide/env.client.mjs";

const SENTRY_DSN = clientEnv.NEXT_PUBLIC_SENTRY_DSN;

if (!SENTRY_DSN) {
	console.warn("Sentry DSN not found for client");
}

Sentry.init({
	dsn: SENTRY_DSN,

	environment: clientEnv.NEXT_PUBLIC_ENV || clientEnv.NODE_ENV,

	// Enable debugging to trouble-shoot problems logging errors
	debug: false,

	// Keep this low in production to avoid using excessive trace allowances.
	// Make it 1.0 to test tracing in development
	tracesSampleRate: 0.01,

	// Log more deeply nested data reported to Sentry (default is 3)
	normalizeDepth: 6,

	// ===============
	// SESSION REPLAYS
	// ===============
	// See https://docs.sentry.io/platforms/javascript/session-replay/#sampling
	// Sample rate to capture session replays in general usage. 0 means none.
	replaysSessionSampleRate: 0,
	// Sample rate to capture session replays when an error occurs (starts from
	// 60 seconds before the error). 0 means none.
	replaysOnErrorSampleRate: 0.01,

	integrations: [
		new Sentry.Replay({
			// Respect users' privacy
			// See https://docs.sentry.io/platforms/javascript/session-replay/privacy/#privacy-configuration
			maskAllText: true,
			maskAllInputs: true,
		}),
	],

	// ...
	// Note: if you want to override the automatic release value, do not set a
	// `release` value here - use the environment variable `SENTRY_RELEASE`, so
	// that it will also get attached to your source maps
});
