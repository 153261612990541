/**
 * Environment variables for use server-side, type-safe and validated by Zod.
 *
 * This file must be .mjs to import into next.config.mjs for build-time
 * validation.
 */

import { z } from "zod";

// Coerce envvar to a truthy value. `1`, `true`, `yes`, `on` are true,
// everything else is false.
export const CoercedBoolean = z.coerce
	.string()
	.transform((value) =>
		["1", "true", "yes", "on"].includes(value?.toLowerCase()),
	);

export const parseResult = z
	.object({
		NODE_ENV: z.enum(["production", "development", "test"]),

		NEXT_PUBLIC_HIDE_UPSELLS: CoercedBoolean.optional().default(false),

		NEXT_PUBLIC_ENV: z.enum(["develop", "test", "preprod", "production"]),

		NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID: z.string(),

		NEXT_PUBLIC_SANITY_PROJECT_ID: z.string(),
		NEXT_PUBLIC_SANITY_DATASET: z.string(),
		NEXT_PUBLIC_SANITY_API_VERSION: z.string(),
		NEXT_PUBLIC_SANITY_USE_CDN: z.boolean().default(true),
		NEXT_PUBLIC_ENABLE_SANITY_CLIENT_SIDE_DRAFTS: z
			.boolean()
			.optional()
			.default(false),

		NEXT_PUBLIC_AOC_RESET: z.string().optional(),

		NEXT_PUBLIC_SENTRY_DSN: z.string().optional(),

		NEXT_PUBLIC_ZOD_PARSE_STRICT: CoercedBoolean.optional().default(false),

		LOG_LEVEL: z.enum(["DEBUG"]).optional(),
	})
	/* eslint-disable no-process-env */
	.safeParse(
		// Instead of just passing in `process.env` we must explicitly pass through
		// every enviroment variable name so Next.js will keep the envvars through
		// its tree-shaking build process
		{
			NODE_ENV: process.env.NODE_ENV,

			NEXT_PUBLIC_HIDE_UPSELLS: process.env.NEXT_PUBLIC_HIDE_UPSELLS,

			NEXT_PUBLIC_ENV: process.env.NEXT_PUBLIC_ENV,

			NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID:
				process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID,

			NEXT_PUBLIC_SANITY_PROJECT_ID: process.env.NEXT_PUBLIC_SANITY_PROJECT_ID,
			NEXT_PUBLIC_SANITY_DATASET: process.env.NEXT_PUBLIC_SANITY_DATASET,
			NEXT_PUBLIC_SANITY_API_VERSION:
				process.env.NEXT_PUBLIC_SANITY_API_VERSION,
			NEXT_PUBLIC_ENABLE_SANITY_CLIENT_SIDE_DRAFTS:
				process.env.NEXT_PUBLIC_ENABLE_SANITY_CLIENT_SIDE_DRAFTS,

			NEXT_PUBLIC_SENTRY_DSN: process.env.NEXT_PUBLIC_SENTRY_DSN,

			NEXT_PUBLIC_ZOD_PARSE_STRICT: process.env.NEXT_PUBLIC_ZOD_PARSE_STRICT,
		},
	);

// If parse failed, log error to console before re-throwing to aid debugging in
// somewhat hostile edge/middleware environments
if (!parseResult.success) {
	const { error } = parseResult;
	console.error("Failed to parse client-side environment variables", error);
	throw error;
}

const { data } = parseResult;

export { data as clientEnv };
